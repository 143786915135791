import React, { Component } from "react";
import {
  Headers,
  Header,
  HeaderContent,
  HeaderBrand,
  HeaderRightZone,
  Icon,
  Button,
  Nav,
  NavItem,
  HeaderToggler,
  Collapse,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  LinkList,
  LinkListItem
} from "design-react-kit";
import { NavLink } from "react-router-dom";
import SessionButton from "./sessionButton";

const SlimHeader = props => {
  return (
    <Header small={false} type="slim">
      <HeaderContent>
        <HeaderBrand responsive tag="a" href="https://fub.it/">
          Fondazione Bordoni/Servizi di Rete
        </HeaderBrand>
        <HeaderRightZone>
          <Button className="btn-icon" color="primary" icon={false} size="full" tag="span">
            <span className="rounded-icon">
              <Icon color="primary" icon="it-user" padding={false} size="" />
            </span>
            <SessionButton {...props} />
          </Button>
        </HeaderRightZone>
      </HeaderContent>
    </Header>
  );
};

class CenterHeader extends Component {
  render() {
    return (
      <Header small={false} type="center">
        <HeaderContent>
          <HeaderBrand iconName="it-code-circle" responsive={false} tag="a" href="/">
            <h2>Servizi di Rete</h2>
            <h3>Richiesta e gestione dei servizi</h3>
          </HeaderBrand>
        </HeaderContent>
      </Header>
    );
  }
}

class NavHeader extends Component {
  state = {
    collapsed: true
  };

  render() {
    const isOpen = !this.state.collapsed;

    return (
      <Header small={false} type="navbar">
        <HeaderContent expand="lg" megamenu>
          <HeaderToggler
            aria-controls="nav1"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => this.setState({ collapsed: isOpen })}
          >
            <Icon icon="it-burger" />
          </HeaderToggler>
          <Collapse isOpen={isOpen} navbar header onOverlayClick={() => this.setState({ collapsed: isOpen })}>
            <div className="menu-wrapper">
              <Nav navbar>
                <NavItem>
                  <NavLink className="nav-link" to={"/"} exact>
                    Home
                  </NavLink>
                </NavItem>
                <UncontrolledDropdown inNavbar nav>
                  <DropdownToggle aria-haspopup caret color="secondary" nav>
                    VPN
                  </DropdownToggle>
                  <DropdownMenu flip tag="div">
                    <LinkList tag="div">
                      <LinkListItem tag="div">
                        <NavLink to={"/vpn/eur/"}>EUR</NavLink>
                      </LinkListItem>
                      <LinkListItem tag="div">
                        <NavLink to={"/vpn/policlinico/"}>Policlinico</NavLink>
                      </LinkListItem>
                    </LinkList>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </div>
          </Collapse>
        </HeaderContent>
      </Header>
    );
  }
}

const AppHeader = props => {
  return (
    <Headers shadow={true} sticky={false}>
      <SlimHeader {...props} />
      <div className="it-nav-wrapper">
        <CenterHeader />
        <NavHeader />
      </div>
    </Headers>
  );
};

export default AppHeader;
