import React, { useState, useEffect } from "react";
import axiosInstance from "../axiosApi";
import { Redirect } from "react-router-dom";
import { saveAs } from "file-saver";
import Errors from "./errors";
import QRCode from "qrcode.react";

const Vpn = props => {
  const [state, setState] = useState({
    config: {},
    config_ini: "",
    redirect: false,
    error: null
  });

  const handleClick = event => {
    const text = event.target.innerText;
    var blob = new Blob([text], { type: "text/plain;charset=utf-8" });
    saveAs(blob, event.target.id);
  };

  const getMessage = async () => {
    try {
      const response = await axiosInstance.get(
        `/vpn/${props.match.params.provider}/`
      );
      const data = response.data;
      const config_ini = data.ini;
      const config = data.json;
      setState({ config_ini: config_ini, config: config });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setState({ redirect: true, error: error });
      }
      setState({ error: error });
    }
  };

  useEffect(() => {
    getMessage();
  }, []);

  if (state.redirect) {
    return (
      <Redirect
        to={{
          pathname: "/login/",
          state: {
            from: { pathname: `/vpn/${props.match.params.provider}/` },
            error: state.error
          }
        }}
      />
    );
  } else if (state.error != null) {
    return (
      <div>
        <div className="row mb-4">
          <div className="col-12 col-lg-12">
            <Errors error={state.error} />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div className="row mb-lg-3">
          <div className="col-12 col-lg-12">
            <p className="mb-2">
              Invia queste informazioni via email a{" "}
              <a href="mailto:helpdesk@fub.it">helpdesk@fub.it</a> per chiedere
              l'attivazione della tua VPN
            </p>
            <pre
              className="p-4 bg-light text-dark rounded"
              id="keys.txt"
              onClick={handleClick}
            >
              WireGuard Server: {state.config.vpn_provider_hostname}:{state.config.port}
              <br />
              Description: {state.config.email}
              <br />
              Public Key: {state.config.public_key}
              <br />
              Allowed IPs: {state.config.address}/32
              <br />
              Pre-shared Key: {state.config.preshared_key}
              <br />
            </pre>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-lg-7">
            <h5>Desktop</h5>
            <ol>
              <li>
                Installa Wireguard secondo le{" "}
                <a
                  href="https://www.wireguard.com/install/"
                  target="_blank"
                  rel="noreferrer"
                >
                  istruzioni per il tuo sistema operativo
                </a>
                .
              </li>
              <li>
                Salva e usa questa configurazione con l'applicazione Wireguard
              </li>
            </ol>
            <pre
              className="p-4 bg-light text-dark rounded"
              id="wg0.conf"
              onClick={handleClick}
            >
              {state.config_ini}
            </pre>
          </div>

          <div className="col-12 col-lg-5">
            <h5>Mobile</h5>
            <ol>
              <li>
                Installa Wireguard per{" "}
                <a href="https://play.google.com/store/apps/details?id=com.wireguard.android">
                  Android
                </a>{" "}
                o{" "}
                <a href="https://itunes.apple.com/us/app/wireguard/id1441195209">
                  iOS
                </a>
              </li>
              <li>
                Inquadra questo codice durante la configurazione di una nuova
                VPN con l'app Wireguard
              </li>
            </ol>
            <div className="text-center rounded">
              <QRCode value={state.config_ini} renderAs={"svg"} size={300} />
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Vpn;
