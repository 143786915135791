import React, { useState } from "react";
import axiosInstance from "../axiosApi";
import LocalStorageService from "../LocalStorageService";
import Errors from "./errors";
import { useHistory, useLocation } from "react-router-dom";
import { Input, Button } from "design-react-kit";

const localStorageService = new LocalStorageService();

const Login = props => {
  const location = useLocation();
  const history = useHistory();
  const { from, error } = location.state || {
    from: { pathname: "/" },
    error: null
  };

  const [state, setState] = useState({
    logging_in: false,
    username: "",
    password: "",
    error: error
  });

  const handleChange = event => {
    const { name, value } = event.target;
    setState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setState({ logging_in: true });
    try {
      const response = await axiosInstance.post("/token/", {
        username: state.username,
        password: state.password
      });
      localStorageService.setToken(response.data);
      props.onRefreshTokenChange(localStorageService.getRefreshToken());
      history.push(from);
      setState({ logging_in: false });
    } catch (exception) {
      setState({ error: exception, logging_in: false });
    }
  };

  return (
    <div>
      <Errors error={state.error} />
      <div className="row mt-4 pt-4">
        <div className="col-12 col-lg-6 mx-auto">
          <form onSubmit={handleSubmit}>
            <Input
              label="Username"
              name="username"
              type="text"
              value={state.username}
              onChange={handleChange}
              infoText="Inserisci il tuo username (la parte che precede la '@' nel tuo indirizzo email)"
            />
            <Input
              label="Password"
              name="password"
              type="password"
              value={state.password}
              onChange={handleChange}
            />
            <Button
              block
              color="primary"
              type="submit"
              disabled={state.logging_in}
            >
              Login
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};
export default Login;
