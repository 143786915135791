import React, { useState, useEffect } from "react";
import axiosInstance from "../axiosApi";
import { Redirect } from "react-router-dom";
import Errors from "./errors";

const Hello = () => {
  const [state, setState] = useState({
    message: null,
    error: null,
    redirect: false,
  });

  const getMessage = async () => {
    try {
      const response = await axiosInstance.get("/hello/");
      const message = response.data.sentence;
      setState({ message: message });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setState({ redirect: true, error: error });
      }
      setState({ error: error });
    }
  };

  useEffect(() => {
    getMessage();
  }, []);

  if (state.redirect) {
    return (
      <Redirect
        to={{
          pathname: "/login/",
          state: { from: { pathname: "/hello/" }, error: state.error },
        }}
      />
    );
  } else {
    return (
      <div>
        <Errors error={state.error} />;
        <p>{state.message}</p>
      </div>
    );
  }
};

export default Hello;
