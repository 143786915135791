import React, { useState } from "react";

import "./App.css";
import { Switch, Route } from "react-router-dom";
import Login from "./components/login";
import Hello from "./components/hello";
import AppHeader from "./components/header";
import Vpn from "./components/vpn";
import Welcome from "./components/welcome";
import "bootstrap-italia/dist/css/bootstrap-italia.min.css";
import "typeface-titillium-web";
import "typeface-roboto-mono";
import "typeface-lora";
import { Container } from "design-react-kit";
import LocalStorageService from "./LocalStorageService";

// If you want to use Fontawesome, uncomment these three lines and add all nedded icons (Refs: https://fontawesome.com/how-to-use/on-the-web/using-with/react)
// import { library } from '@fortawesome/fontawesome-svg-core'
// import { faHome } from '@fortawesome/free-solid-svg-icons'
// library.add(faHome)

const localStorageService = new LocalStorageService();

const App = () => {
  const [state, setState] = useState({
    refreshToken: localStorageService.getRefreshToken()
  });

  const handleRefreshTokenChange = refreshToken => {
    setState({ refreshToken: refreshToken });
  };

  return (
    <div className="App">
      <AppHeader
        refreshToken={state.refreshToken}
        onRefreshTokenChange={handleRefreshTokenChange}
      />
      <main>
        <Container className="px-4 my-4" id="main-content" tag="section">
          <Switch>
            <Route exact path={"/login/"}>
              <Login onRefreshTokenChange={handleRefreshTokenChange} />
            </Route>
            <Route exact path={"/hello/"} component={Hello} />
            <Route path={"/vpn/:provider"} component={Vpn} />
            <Route exact path={"/"}>
              <Welcome refreshToken={state.refreshToken} />
            </Route>
          </Switch>
        </Container>
      </main>
    </div>
  );
};

export default App;
