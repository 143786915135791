class LocalStorageService {
  setToken = tokenObj => {
    console.log(
      `Salvataggio dei token: ${tokenObj.access}/${tokenObj.refresh}`
    );
    localStorage.setItem("access_token", tokenObj.access);
    localStorage.setItem("refresh_token", tokenObj.refresh);
  };

  getAccessToken = () => {
    return localStorage.getItem("access_token");
  };

  getRefreshToken = () => {
    return localStorage.getItem("refresh_token");
  };

  clearToken = () => {
    console.log("Eliminazione dei vecchi token");
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
  };
}

export default LocalStorageService;
