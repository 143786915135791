import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import { Redirect } from "react-router-dom";

const CONTENT = `
Benvenuto sul sito per la richiesta e gestione dei Servizi di Rete della Fondazione Bordoni.

Questa piattaforma è disponibile per agevolare la richiesta e, in futuro, visualizzare tutti i servizi richiesti a Servizi di Rete.

Questi sono i servizi che, attualmente, è possibile richiedere su questa piattaforma:

* Richiesta di **accesso VPN personale** (i due servizi VPN sono equivalenti e interscambiabili, disponibili entrambi come backup l'uno dell'altro):
  * [Servizio VPN erogato dalla sede dell'EUR](/#/vpn/eur)
  * [Servizio VPN erogato dalla sede di Policlinico](/#/vpn/policlinico)

Per tutte le altre richieste è ancora necessario inviare una mail al servizio di [Helpdesk](mailto:helpdesk@fub.it).
`;

const Welcome = props => {
  if (props.refreshToken == null) {
    return (
      <Redirect
        to={{
          pathname: "/login/",
          state: { from: { pathname: "/" } }
        }}
      />
    );
  } else {
    return <ReactMarkdown>{CONTENT}</ReactMarkdown>;
  }
};

export default Welcome;
