import React, { useState } from "react";
import LocalStorageService from "../LocalStorageService";
import { Link, Redirect } from "react-router-dom";
import axiosInstance from "../axiosApi";

const localStorageService = new LocalStorageService();

const SessionButton = props => {
  const [state, setState] = useState({
    redirect: false,
  });

  const handleLogout = async () => {
    try {
      const refreshToken = localStorageService.getRefreshToken();
      const response = await axiosInstance.post("/token/blacklist/", {
        refresh_token: refreshToken,
      });
      localStorageService.clearToken();
      axiosInstance.defaults.headers["Authorization"] = null;
      props.onRefreshTokenChange(null);
      if (response.code === 205) {
        setState({ redirect: true });
      }
    } catch (e) {
      console.log(e);
    }
  };

  if (state.redirect) {
    return <Redirect to={"/"} />;
  }

  if (props.refreshToken != null) {
    return (
      <Link onClick={handleLogout} to={"/"}>
        <span className="d-none d-lg-block">Logout</span>
      </Link>
    );
  } else {
    return (
      <Link to={"/login/"}>
        <span className="d-none d-lg-block">Accedi</span>
      </Link>
    );
  }
};

export default SessionButton;
