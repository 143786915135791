import axios from "axios";

import LocalStorageService from "./LocalStorageService";

const localStorageService = new LocalStorageService();

const baseURL = window.baseURL;
const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json"
  }
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  config => {
    const accessToken = localStorageService.getAccessToken();
    if (accessToken) {
      config.headers["Authorization"] = `JWT ${accessToken}`;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    const originalRequest = error.config;

    /* Qui possono finire le richieste di:
     * - autenticazione con credenziali sbagliate
     * - accesso non autorizzato
     * - richieste con token scaduto
     * - altri tipi di errore
     */

    // Errore di autenticazione con credenziali errate
    if (
      error.response &&
      error.response.status === 401 &&
      (originalRequest.url === "/token/" ||
        originalRequest.url === "/token/refresh/")
    ) {
      console.log("Errore di autenticazione con credenziali errate");
      localStorageService.clearToken();
      return Promise.reject(error);
    } else if (error.response && error.response.status === 401) {
      console.log("Refresh del token");
      const refresh_token = localStorageService.getRefreshToken();
      if (refresh_token) {
        return axiosInstance
          .post("/token/refresh/", { refresh: refresh_token })
          .then(response => {
            const tokenObj = response.data;

            localStorageService.setToken(tokenObj);
            const accessToken = localStorageService.getAccessToken();

            axios.defaults.headers["Authorization"] = `JWT ${accessToken}`;
            originalRequest.headers["Authorization"] = `JWT ${accessToken}`;

            return axiosInstance(originalRequest);
          })
          .catch(exception => {
            console.error(`Errore durante il refresh del token ${exception}`);
            return Promise.reject(exception);
          });
      } else {
        console.error("Refresh token non presente");
        return Promise.reject(error);
      }
    }

    // Tutti i tipi di errore esclusi quelli di autenticazione
    return Promise.reject(error);
  }
);

export default axiosInstance;
